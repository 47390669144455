<template>
  <div class="completed">
    <h3>已完成</h3>
    <ul>
      <li>
        <div>
          <span>面试岗位：</span>
          <span>初级前端工程师</span>
          <span>期望薪资：</span>
          <span>6000元/月</span>
        </div>
      </li>
      <li>
        <span>预约时间：</span>
        <span>2022/06/20 08:0（周一）</span>
      </li>
      <li class="suggest">
        <span>面试官建议</span>
        <p>HTML部分不够熟悉，对常用标签以及标签语义化的意义不够熟悉和清楚。CSS部分不够系统，对于CSS属性的分类掌握不是很到位。Javascript部分需要进一步巩固基础和加强逻辑训练。Vue部分基础不错，但由于前三者基础欠佳，所以在处理Vue问题是不一定能快速准确。期望薪资6000的话，可以，但需要靠缘分。</p>
      </li>
      <li>
        <p>站长提示：面试官建议并不代表企业真实想法，仅作为技术学习上的参考建议。</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'UserAppointInterviewCompleted'
}
</script>

<style scoped lang="scss">
  @import "../../../../assets/style/variables";
  .completed {
    background-color: $background-color-second;
    margin: 20px 30px;
    padding: 10px 20px;
    border-radius: 15px;
    // 小标题
    h3 {
      font-size: 19px;
      margin: 0;
    }
    // 结果
    ul {
      margin-top: 10px;
      padding: 0;
      // 建议
      .suggest {
        margin-top: 10px;
        padding: 10px 20px;
        line-height: 20px;
        background-color: white;
        border-radius: 15px;
        color: #1D1D1D;
        p {
          font-size: 13px;
          margin: 5px 0;
        }
      }
      li {
        font-size: 15px;
        margin: 5px 0;
        color: $font-color-second;
        // 时间
        &:nth-of-type(2) {
          span {
            &:nth-of-type(2) {
              font-weight: 600;
              color: #1D1D1D;
            }
          }
        }
        // 岗位/薪资
        div {
          span {
            &:nth-of-type(3) {
              margin-left: 70px;
            }
            &:nth-of-type(even) {
              font-weight: 600;
              color: #1D1D1D;
            }
          }
        }
        &:last-of-type {
          p {
            margin-bottom: -5px;
            font-size: 14px;
          }
        }
      }
    }
  }
</style>

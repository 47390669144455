<template>
  <div class="stay-interview">
    <h3>待面试</h3>
    <ul>
      <li>
        <div>
          <span>面试岗位：</span>
          <span>初级前端工程师</span>
          <span>期望薪资：</span>
          <span>6000元/月</span>
        </div>
      </li>
      <li>
        <span>预约时间：</span>
        <span>2022/06/20 08:0（周一）</span>
      </li>
      <li>
        <p>站长提醒：请妥善安排好自己的时间准时参加面试，面试官将会在预约时间以手机号码方式联系您。</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'UserAppointInterviewWaiting'
}
</script>

<style scoped lang="scss">
  @import "../../../../assets/style/variables";
  .stay-interview {
    background-color: $background-color-second;
    margin: 20px 30px;
    padding: 10px 20px;
    border-radius: 15px;
    // 小标题
    h3 {
      font-size: 19px;
      margin: 0;
    }
    // 结果
    ul {
      margin-top: 10px;
      padding: 0;
      li {
        font-size: 15px;
        margin: 5px 0;
        color: $font-color-second;
        // 时间
        &:nth-of-type(2) {
          span {
            &:nth-of-type(2) {
              font-weight: 600;
              color: #1D1D1D;
            }
          }
        }
        // 岗位/薪资
        div {
          span {
            &:nth-of-type(3) {
              margin-left: 70px;
            }
            &:nth-of-type(even) {
              font-weight: 600;
              color: #1D1D1D;
            }
          }
        }
        &:last-of-type {
          padding: 0;
          color: $font-color-red;
          font-size: 15px;
          p {
            font-size: 14px;
            margin-bottom: -5px;
          }
        }
      }
    }
  }
</style>

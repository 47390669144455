<template>
  <div class="Appoint">
    <div class="message">
      <h2>模拟面试</h2>
      <Completed/>
      <Waiting/>
    </div>
    <div class="paging">
      <Pagination/>
    </div>
  </div>
</template>

<script>
import Completed from '@/components/usr/appoint/interview/Completed'
import Waiting from '@/components/usr/appoint/interview/Waiting'
export default {
  name: 'Appoint',
  components: { Waiting, Completed }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/variables";
  .Appoint {
    width: 100%;
    height: 940px;
    border-radius: 15px;
    background-color: $background-color;
    display: flex;
    flex-direction: column;
    // 标题
    h2 {
      margin: 0 20px;
      padding: 15px 5px 7px 10px;
      box-sizing: border-box;
      font-size: 16px;
      color: $font-color-third;
      border-bottom: 1px solid $font-color-third;
    }
    .message {
      flex-grow: 1;
    }
    // 分页
    .paging {
      height: 70px;
      flex-shrink: 0;
    }
  }
</style>
